import React from "react"

function Jo({ ...props }) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 567 489"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={280} cy={248} r={80} fill="#4C596A" />
      <g clipPath="url(#a)">
        <path
          d="M404.5 0A162.5 162.5 0 0 0 242 160v2.5c0 4.1.17 7.05.49 12.77.17 2.93.37 6.36.51 8.73-.5-13.333-.833-20-1-20-.167 0-.167 6.913 0 20.74v129.08c.23 1.38.41 2.77.55 4.18a80.495 80.495 0 0 1-49.244 82.872A80.5 80.5 0 1 1 162.5 246v-82A162.392 162.392 0 0 0 54.37 447.447 162.391 162.391 0 0 0 323.39 303.55V169c-.23-2.48-.35-5-.35-7.54v-1.5A80.478 80.478 0 0 1 403.5 81c1 0 2 0 3 .06a80.503 80.503 0 0 1 68.947 44.368 80.493 80.493 0 0 1-5.704 81.791A80.5 80.5 0 0 1 329.35 192.88V306.59A162.511 162.511 0 1 0 404.5 0Z"
          fill="#4C596A"
        />
        <path d="M329.35 303.25h-.03v.05h.03v-.05Z" fill="#fff" />
        <path d="M329.36 303.25h-.04v.3h.04v-.3Z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h567v489H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Jo
