import React from "react"

function ScrollUp({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#527693"
      width={48}
      height={48}
      viewBox="0 0 688.64 688.64"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M344.32.5C154.43.5.5 154.43.5 344.32s153.93 343.82 343.82 343.82 343.82-153.93 343.82-343.82S534.21.5 344.32.5Zm0 72.46a67.84 67.84 0 1 1-67.84 67.84A67.84 67.84 0 0 1 344.32 73Zm125.74 363.18a33.92 33.92 0 0 1-44.77 2.86l-3.2-2.83-43.85-43.85v189.44a33.92 33.92 0 0 1-67.61 4l-.23-4V392.29l-43.85 43.85a33.92 33.92 0 0 1-50.79-44.77l2.82-3.2 125.74-125.74 125.74 125.74a33.91 33.91 0 0 1 0 47.97Z"
          style={{
            stroke: "#527693",
            strokeMiterlimit: 10,
          }}
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default ScrollUp
