import React from "react"

function Dribbble({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <path d="M11.455 9.985c-1.419.417-3.11.632-5.067.646a5.798 5.798 0 0 1 3.003-3.784 26.112 26.112 0 0 1 2.064 3.138zm.965 1.93a21.04 21.04 0 0 0-.391-.835c-1.622.51-3.561.769-5.804.772L6.217 12c0 1.404.504 2.692 1.34 3.695 1.266-1.901 2.891-3.164 4.863-3.78zm-3.97 4.641a5.73 5.73 0 0 0 5.624.836 22.837 22.837 0 0 0-1.19-4.352c-1.819.542-3.285 1.714-4.434 3.516zm7.075-9.13A5.75 5.75 0 0 0 12 6.217c-.49 0-.964.068-1.418.184a27.176 27.176 0 0 1 2.035 3.172c1.236-.524 2.204-1.24 2.908-2.147zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-5 0a7 7 0 1 0-14.001.001A7 7 0 0 0 19 12zm-5.824-1.349c.157.324.305.651.447.98 1.26-.217 2.641-.204 4.143.042a5.748 5.748 0 0 0-1.354-3.403c-.807 1.005-1.89 1.798-3.236 2.381zm.914 2.132a23.936 23.936 0 0 1 1.119 4.023 5.797 5.797 0 0 0 2.497-3.909c-1.317-.228-2.522-.268-3.616-.114z" />
    </svg>
  )
}

export default Dribbble
